import $ from 'jquery';
import Header from './sections/header';
import TextSlider from './sections/text-slider';
import CompanySlider from './sections/company-thumbnail-slider';
import Slider from './sections/slider';
import ContactForm from './sections/contact-form';
import VideoTemplate from './sections/video-template';
import CompassNavigation from './sections/compass-navigation';
import Collapsible from './sections/collapsible';
import Toggle from './sections/toggle';
import focusWithin from 'focus-within';
import Collapse from './sections/collapse';
import BlackWhite from './sections/black-white';
import People from './sections/people';
import EctsOverview from './sections/ects-overview';
import ProgramFinder from './sections/program-finder';
import HrForm from './sections/hr-application-form';
import FhModal from './sections/fh-modal';
import RegistrationForm from './sections/registration-form';
import PageNews from './pages/page-news';
import StickToTop from './sections/stick-to-top'

import LazyLoad from 'vanilla-lazyload';

// fixing height on mobile
const $vh = $(window).height() * 0.01;
$(document.documentElement).css('--vh', `${$vh}px`);

$(document).ready(() => {
    new StickToTop().init();
    new Header().init();
    new TextSlider().init();
    new CompanySlider().init();
    new Slider().init();
    new ContactForm().init();
    new VideoTemplate().init();
    new CompassNavigation().init();
    new Collapsible().init();
    new Toggle().init();
    new Collapse().init();
    new BlackWhite().init();
    new People().init();
    new ProgramFinder().init();
    new FhModal().init();
    new PageNews().init();
    new LazyLoad({
        elements_selector: ".lazyload",
    });

    $('header').keydown(
        function(e) {
            if (e.keyCode === 38) {
                if($('.menu-item-link').is(':focus') || $('.sub-menu').is(':visible') ){
                        e.preventDefault();
                        let focusedElement = document.activeElement;
                        let index = $('.menu-item-link').index(focusedElement);
                        if (index > 0) {
                            let menuElements = $('.menu-item-link');
                            $(menuElements[index - 1]).focus();
                        }
                    }
            } else if (e.keyCode === 40) {
                if($('.menu-item-link').is(':focus') || $('.sub-menu').is(':visible') ){
                    e.preventDefault();
                    let focusedElement = document.activeElement;
                    let index = $('.menu-item-link').index(focusedElement);
                    let menuElements = $('.menu-item-link');
                    if (index < menuElements.length - 1) {
                        $(menuElements[index + 1]).focus();
                    }
                }
            } else if (e.keyCode === 39) {
                if ( $('.sub-menu').is(':visible') && e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'TEXTAREA') {
                    e.preventDefault();
                    let subMenus = $('.sub-menu');
                    $(subMenus).each(function(i, element) {
                        if ( $(element).is(':visible') ) {
                            if ( $(element).find('.sub-menu-item > a').is(':focus') ) {
                                let el = document.activeElement;
                                let subMenuItems = $(element).find('.sub-menu-item > a');
                                let index = $(subMenuItems).index(el);
                                $(subMenuItems[(index%subMenuItems.length)+1]).focus();
                            }
                            else {
                                let subMenuItems = $(element).find('.sub-menu-item > a');
                                $(subMenuItems[0]).focus();
                            }
                        }
                    });
                }
            } else if (e.keyCode === 37) {
                if ( $('.sub-menu').is(':visible') && e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'TEXTAREA' ) {
                    e.preventDefault();
                    let subMenus = $('.sub-menu');
                    $(subMenus).each(function(i, element) {
                        if ( $(element).is(':visible') ) {
                            if ( $(element).find('.sub-menu-item > a').is(':focus') ) {
                                let el = document.activeElement;
                                let subMenuItems = $(element).find('.sub-menu-item > a');
                                let index = $(subMenuItems).index(el);
                                $(subMenuItems[(index%subMenuItems.length)-1]).focus();
                            }
                            else {
                                let subMenuItems = $(element).find('.sub-menu-item > a');
                                $(subMenuItems[0]).focus();
                            }
                        }
                    });
                }
            }
        }
    );

    focusWithin(document);

    $('select').on('change', function () {
        $(this).find('option').each(function() {
            $(this).attr('aria-selected', 'false')
                .removeAttr('selected');
        })
        $(this).find(':selected')
            .attr('aria-selected', 'true')
            .attr('selected', 'true');
    });

    new HrForm().init();
    new EctsOverview().init();
    new RegistrationForm().init();

    $('.btn').click(function(){
        $('body').append($(this).data('script'));
    })


});
